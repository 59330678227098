<template>
  <div>
    <div class="bg-white">
      <div
        class="bg-white flex flex-row justify-center box-border px-2 py-1 items-center mt-5"
      >
        <div class="w-[120px] font-bold">银联卡号</div>
        <input
          class="border-none flex flex-1 px-2 py-3 outline-none new_input"
          disabled
          v-model="card"
          placeholder="信用卡/储蓄卡"
        />
      </div>
      <el-divider v-if="!cardState" />
      <div
        v-if="!cardState"
        class="bg-white flex flex-row justify-center box-border px-2 py-1 items-center"
      >
        <div class="w-[120px] font-bold">持卡人姓名</div>
        <input
          class="border-none flex flex-1 px-2 py-3 outline-none new_input"
          type="text"
          v-model="form.name"
          placeholder="请输入在持卡人姓名"
        />
      </div>
      <el-divider v-if="!cardState" />
      <div
        v-if="!cardState"
        class="bg-white flex flex-row justify-center box-border px-2 py-1 items-center"
      >
        <div class="w-[120px] font-bold">身份证号</div>
        <input
          class="border-none flex flex-1 px-2 py-3 outline-none new_input"
          type="text"
          v-model="form.idCard"
          placeholder="请输入在银行办理该卡时使用的身份证号"
        />
      </div>
      <el-divider />
      <div
        class="bg-white flex flex-row justify-center box-border px-2 py-1 items-center"
      >
        <div class="w-[120px] font-bold">预留手机号</div>
        <input
          class="border-none flex flex-1 px-2 py-3 outline-none new_input"
          type="number"
          v-model="form.phone"
          placeholder="银行预留手机号"
        />
      </div>
      <el-divider />
      <div
        class="bg-white flex flex-row justify-center box-border px-2 py-1 items-center"
      >
        <div class="w-[120px] font-bold">验证码</div>
        <input
          class="border-none flex flex-1 px-2 py-3 outline-none new_input w-[100px]"
          type="number"
          v-model="form.code"
          placeholder="请输入有效验证码"
        />
        <el-button type="primary" class="mr-2" @click="getCode" :disabled="codeState">{{
          codeText
        }}</el-button>
      </div>
    </div>
    <el-button
      class="w-[92%] mt-7"
      color="#ED171F"
      size="large"
      :disabled="vaildState"
      @click="makeSure"
      >确认支付</el-button
    >

    <Footer />
    <message-boxs :type="`false`" :show="showToast" :text="errorText" />
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch, defineEmits, onMounted } from "vue";
import { getCodeNo, pay } from "@/api/host";
import { useStore } from "vuex";

const card = computed(() => {
  const num = sessionStorage.getItem("card").toString();
  const str1 = num.slice(0, 4);
  const str2 = num.slice(num.length - 4, num.length);
  const str = str1 + " **** " + "**** " + "**** " + str2;
  return str;
});
const emits = defineEmits(["getPayStatus"]);
const store = useStore();
const codeText = ref("获取验证码");
const errorText = ref("");
const vaildState = ref(true);
const showToast = ref(false);
const timer = ref(null);
const codeState = ref(true);
const cardState = computed(() => store.getters.cardState);
const form = reactive({
  name: null,
  idCard: null,
  phone: null,
  code: null,
});

const vaildparm = (type) => {
  const reg1 = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  const phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
  if (
    (form.name === null || form.name === "" || form.name === undefined) &&
    !cardState.value
  ) {
    errorText.value = "请输入持卡人姓名 ！";
    showToast.value = true;
    return false;
  }
  if (!reg1.test(form.idCard) && !cardState.value) {
    errorText.value = "身份证号码不正确 ！";
    showToast.value = true;
    return false;
  }
  if (!phoneReg.test(form.phone)) {
    errorText.value = "电话号码不正确 ！";
    showToast.value = true;
    return false;
  }
  if (type === "code") {
    showToast.value = false;
    return true;
  }
  //验证码是否正确
  if (
    !form.code ||
    (form.code.toString().length !== 6 && form.code.toString().length !== 4)
  ) {
    errorText.value = "验证码不合法 ！";
    showToast.value = true;
    return false;
  }
  if (type === "") {
    return true;
  }
  //接口请求支付
};

const getCode = async () => {
  const state = await vaildparm("code");
  if (state) {
    let obj = {
      cardNo: sessionStorage.getItem("card").toString(),
      cardHolder: form.name,
      phoneNo: form.phone,
      identityNo: form.idCard,
      merchantOrderNo: store.getters.orderInfo.num,
    };
    getCodeNo(obj).then((res) => {
      codeState.value = true;
      if (res.code !== -1) {
        codeText.value = "60s";
        let num = 60;
        timer.value = setInterval(() => {
          num--;
          codeText.value = num + "s";
          if (num === 0) {
            codeText.value = "获取验证码";
            codeState.value = false;
            clearInterval(timer.value);
          }
        }, 1000);
      } else {
        codeState.value = false;
      }
    });
  }
};

const makeSure = async () => {
  const state = await vaildparm("");
  if (state) {
    pay("1", { smsCode: form.code, merchantOrderNo: store.getters.orderInfo.num }).then(
      (res) => {
        if (res.code === 0 && res.url) {
          emits("getPayStatus", { state: true, url: res.url });
        } else {
          emits("getPayStatus", { state: false, url: "" });
        }
      }
    );
  }
};

onMounted(() => {});

watch(
  () => form,
  (newval, oldval) => {
    if (newval) {
      let num = "";
      for (const i in newval) {
        if (newval[i] !== null && newval[i] !== "") {
          num += i;
        }
      }
      if (num === "nameidCardphone" && !cardState.value && codeState.value) {
        codeState.value = false;
      }
      if (num === "phone" && cardState.value && codeState.value) {
        codeState.value = false;
      }
      if (num === "nameidCardphonecode" && !cardState.value) {
        vaildState.value = false;
      } else if (num === "phonecode" && cardState.value) {
        vaildState.value = false;
      } else {
        vaildState.value = true;
      }
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
:deep(.el-divider) {
  width: 90%;
  margin: 5px auto;
}
</style>
