<template>
  <div>
    <div class=" relative bg-[#F8F8F8] pb-[20px]" v-if="!isMobile" style="border-bottom: #d6d7e6 solid 1px">
      <div class="w-[950px] h-[70px] m-auto flex flex-row justify-between items-center box-border">
        <div class=" flex items-center">
          <img src="../../assets/open_logo_index_top.gif" alt="">
          <el-divider class=" bg-[#36c] ml-3" direction="vertical" />
          <span class=" ml-1 text-[#3F607A] font-bold">收银台</span>
        </div>
        <div class=" flex items-center">
          <img src="../../assets/hot_phone.gif" alt="">
        </div>
      </div>
      <div class=" w-[100vw] h-1 bg-[#5476AF]">
      </div>
      <div class=" w-[950px] h-auto m-auto" v-if="!payState">
        <div
          class="h-[70px] w-full bg-[#e9ecec] m-auto flex justify-between flex-row items-center box-border px-5 py-2 mt-3 text-sm">
          <div class="flex items-center">订单金额：<span class="text-[#F0791B] text-xl">{{ order.price }}</span>元</div>
          <div>订单编号：<span>{{ order.num }}</span></div>
          <!-- <div>商户名称：<span>{{ order.shop }}</span></div> -->
        </div>
        <div class="w-full h-auto bg-[#86ACD3] rounded mt-3">
          <div class=" h-[40px] flex justify-start ">
            <div
              class=" box-border border-[#7EA6CC] py-2 px-6 text-sm font-bold text-[#4678AD] new_bg ml-2 mt-1 shadow_new">
              银联卡支付
            </div>
          </div>
          <card-info v-if="!cardStatus" @getCard="getCardInfo" />
          <id-info @getPayStatus="getCardStatus" v-else />

        </div>
      </div>
      <success-pay v-else :successState="successState" :url="_url" />

    </div>
    <div v-else class=" relative w-[100vw] bg-[#F8F8F8]" :style="`height:${height}`">
      <div v-if="!payState">
        <div class="h-[50px] bg-white text-lg font-bold text-center"
          style="line-height: 50px;border-bottom: 1px solid #d6d7e6;">银行卡付款
        </div>
        <div class="text-4xl font-bold bg-white py-5">
          ￥{{ order.price }}
        </div>
        <!-- <div class="line">
          <div class="line_top">商户名称</div>
          <div class="line_down">{{ order.shop }}</div>
        </div> -->
        <div class="line">
          <div class="line_top">订单编号</div>
          <div class="line_down">{{ order.num }}</div>
        </div>
        <!-- <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item name="1">
            
            <div class="line">
              <div class="line_top">创建时间</div>
              <div class="line_down">{{ '2024-2-27 15：00' }}</div>
            </div>
          </el-collapse-item>
        </el-collapse> -->
        <phone-card @getCard="getCardInfo" v-if="!cardStatus" />
        <phone-user v-else @getPayStatus="getCardStatus" />
      </div>
      <phone-success :successState="successState" :url="_url" v-else />

    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { mobile, decryptDes } from '@/utils/common';
import { getUrl } from '@/api/host';
import { useStore } from 'vuex';

const isMobile = ref(mobile() != null)
const payState = ref(false)
const cardStatus = ref(false)
const successState = ref(false)
const store = useStore()
const height = ref('')
const top = ref('')
const _url = ref('')
const order = ref({
  price: '199.00',
  num: '4567845678563545345564745',
  shop: 'test商家12123123123213'
})
// computed(()=>{
//  
// })

const getCardInfo = (state) => {
  cardStatus.value = state
}

const getInfo = () => {
  const url = window.location.href
  let obj = {}
  if (url.includes('code')) {
    const str1 = url.split('code=')[1]
    const str = decryptDes(str1)
    if (str.includes('cardNo')) {
      const arr1 = str.split('amount=')[1].split('&orderNo=')
      const arr2 = arr1[1].split('&cardNo=')
      sessionStorage.setItem('card', arr2[1])
      store.dispatch('user/setCardState', true)
      if (store.getters.orderInfo.num !== arr2[0]) {
        sessionStorage.setItem('cardStatus', true)
        sessionStorage.removeItem('payState')
        cardStatus.value = true
        payState.value = false
      } else {
        sessionStorage.setItem('cardStatus', true)
        const payValue = sessionStorage.getItem('payState')
        cardStatus.value = true
        if (payValue === 'true') {
          payState.value = true
        } else {
          payState.value = false
        }
      }
      obj = {
        price: arr1[0],
        num: arr2[0],
        shop: 'test商家'
      }
    } else {
      const arr = str.split('amount=')[1].split('&orderNo=')
      if (store.getters.orderInfo.num !== arr[1]) {
        sessionStorage.removeItem('cardStatus')
        sessionStorage.removeItem('payState')
        cardStatus.value = false
        payState.value = false

      } else {
        const state = sessionStorage.getItem('cardStatus')
        const payValue = sessionStorage.getItem('payState')
        if (state) {
          cardStatus.value = true
        } else {
          cardStatus.value = false
        }
        if (payValue === 'true') {
          payState.value = true
        } else {
          payState.value = false
        }
      }
      obj = {
        price: arr[0],
        num: arr[1],
        shop: 'test商家'
      }
    }
  } else {
    obj = {
      price: 100,
      num: '888888888',
      shop: 'test商家',
    }
  }
  store.dispatch('user/setInfo', obj)
  order.value = JSON.parse(JSON.stringify(obj))
}

const getCardStatus = (state) => {
  payState.value = true
  sessionStorage.setItem('payState', 'true')
  successState.value = state.state
  _url.value = state.url
}

onMounted(() => {
  const h = window.innerHeight
  height.value = `${h}px`
  window.addEventListener('resize', (e) => {
    if (e.target.innerHeight < h) {
      top.value = 'none'
    } else {
      top.value = ''
    }
  })
  getInfo()

})

</script>

<style lang="scss" scoped>
.new_bg {
  background: linear-gradient(white, #C4D5E7);
}

.shadow_new {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 1px solid #396C9C;
  border-left: 1px solid #396C9C;
  border-right: 1px solid #396C9C;
  z-index: 1;
}

.border_color {
  border: 1px solid #c6c6c6;
}

.new_btn {
  width: 100px;
  margin-top: 40px;
  background: linear-gradient(#79ACE5, #406695);
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;

  .line_top {
    font-weight: 600;
  }

  .line_down {
    font-size: 14px;
    color: #6C6E72;
  }

}

:deep(.el-collapse) {
  border: none;
}

:deep(.el-collapse-item__header) {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15px;
}

:deep(.el-collapse-item__arrow) {
  position: absolute;
  transform: rotate(90deg);
}
</style>
