
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-icon-success-phone\"><path d=\"M0 510.692a510.578 510.578 0 0 0 255.716 442.254 512 512 0 0 0 511.43 0 510.578 510.578 0 0 0 255.716-442.254C1022.862 228.636 793.884 0 511.432 0S0 228.693 0 510.692\" fill=\"#69b076\" /><path d=\"M839.054 374.898 465.237 780.914a46.763 46.763 0 0 1-34.588 15.53 46.763 46.763 0 0 1-34.475-15.53L184.89 551.367a54.955 54.955 0 0 1-14.222-37.376c0-29.298 21.845-52.963 48.753-52.963 13.483 0 25.657 5.916 34.475 15.53l176.81 192 339.4-368.64a46.933 46.933 0 0 1 34.475-15.474c26.908 0 48.753 23.723 48.753 52.907a55.182 55.182 0 0 1-14.279 37.547\" fill=\"#FFF\" /></symbol><symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-icon-success\"><defs><linearGradient id=\"icon-icon-success_a\" x1=\".1\" x2=\"1\" y1=\".1\" y2=\"1\"><stop offset=\"0%\" stop-color=\"#E5F59F\" /><stop offset=\"100%\" stop-color=\"#5FB617\" /></linearGradient></defs><path d=\"M0 510.692a510.578 510.578 0 0 0 255.716 442.254 512 512 0 0 0 511.43 0 510.578 510.578 0 0 0 255.716-442.254C1022.862 228.636 793.884 0 511.432 0S0 228.693 0 510.692\" fill=\"url(#icon-icon-success_a)\" /><path d=\"M839.054 374.898 465.237 780.914a46.763 46.763 0 0 1-34.588 15.53 46.763 46.763 0 0 1-34.475-15.53L184.89 551.367a54.955 54.955 0 0 1-14.222-37.376c0-29.298 21.845-52.963 48.753-52.963 13.483 0 25.657 5.916 34.475 15.53l176.81 192 339.4-368.64a46.933 46.933 0 0 1 34.475-15.474c26.908 0 48.753 23.723 48.753 52.907a55.182 55.182 0 0 1-14.279 37.547\" fill=\"#FFF\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}