<template>
    <div>
        <el-dialog v-model="dialogVisible" title="" align-center @close="closeDio" width="220" class=" rounded-lg">
            <div>
                <img class=" w-[50px] h-[50px]" :src="showImg" />
                <div class="text-[18px] font-bold text-[#333] mt-8">{{text}}</div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script setup>
import { ref, defineProps, computed, watch } from 'vue';
import successImg from '@/assets/success.png'
import errorImg from '@/assets/error.png'

const prpos = defineProps({
    type: {
        type: String,
        default: 'success'
    },
    text: {
        type: String,
        default: '提示'
    },
    show:{
        type:Boolean,
        default:false
    }
})
const dialogVisible = ref(false)
const showImg = computed(() =>{
    if(prpos.type === 'false'){
        return errorImg
    } else {
        return successImg
    }
})

const closeDio = ()=>{
    dialogVisible.value = false
}

const text = computed(() =>{
    return prpos.text
})

watch(()=>prpos.show,(newval)=>{
    if(newval){
        dialogVisible.value = true
    } else {
        dialogVisible.value = false
    }
})

</script>
  
<style lang="scss" scoped>
:deep(.el-dialog){
  border-radius: 10px !important;
  box-shadow: 0 0 2px #e6e6e6;
}

:deep(.el-dialog__headerbtn){
  display: none;
}

</style>
  