<template>
    <div class="w-full h-[509px] indent-2 bg-[#C4D5E7] box-border p-1">
        <div class="w-full h-[500px] bg-white flex flex-col justify-center items-center">
            <div class="form">
                <div class=" flex flex-row justify-start items-center">
                    <div class=" w-[120px] text-right p-0 text-sm ">
                        银联卡号:
                    </div>
                    <div class="ml-2">
                        <input class=" w-[280px] py-2 border_color" style="font-weight: 600;" v-model="card" type="text"
                            disabled>
                    </div>
                </div>
                <div v-if="!cardState"  class=" flex flex-row justify-start items-center mt-3">
                    <span class="w-[120px] text-right p-0 text-sm">
                        证件号:
                    </span>
                    <div class="flex flex-col ml-2">
                        <div class="flex flex-row items-center h-[30px]">
                            <el-select v-model="value" class="m-2" size="small" style="width: 90px">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                            <div>
                                <input class=" w-[175px] py-1 border_color" v-model="form.idCard" type="text"
                                    :class="errorStatus.idCard ? 'error' : ''">
                                <span v-if="errorStatus.idCard"
                                    class=" text-[10px] text-red-400 ml-3">请输入正确的身份证号！</span>
                            </div>
                        </div>
                        <div class="text-[#8D9095]" style="font-size: 10px;">请输入在银行办理该卡时使用的身份证号</div>
                    </div>
                </div>
                <div v-if="!cardState" class=" flex flex-row justify-start items-center  mt-3">
                    <div class="w-[120px] text-right p-0 text-sm">
                        持卡人姓名:
                    </div>
                    <div class="ml-2">
                        <input class=" w-[220px] py-1 border_color" v-model="form.name" type="text"
                            :class="errorStatus.name ? 'error' : ''">
                        <span v-if="errorStatus.name" class=" text-[10px] text-red-400 ml-3">请输入持卡人姓名！</span>
                    </div>
                </div>
                <div class=" flex flex-row justify-start items-center  mt-3">
                    <div class="w-[120px] text-right p-0 text-sm">
                        银行预留手机号:
                    </div>
                    <div class="ml-2">
                        <input class=" w-[220px] py-1 border_color" v-model="form.phone" type="number"
                            :class="errorStatus.phone ? 'error' : ''">
                        <span v-if="errorStatus.phone" class=" text-[10px] text-red-400 ml-3">请输入正确的手机号码！</span>
                    </div>
                </div>
                <div class=" flex flex-row justify-start items-center  mt-3">
                    <div class="w-[120px] text-right p-0 text-sm">
                        短信验证码:
                    </div>
                    <div class="ml-2">
                        <input class=" w-[80px] py-1 border_color" v-model="form.messageCode" type="number"
                            :class="errorStatus.messageCode ? 'error' : ''">
                        <button @click="getCode"
                            class=" w-[80px] py-[5px] text-xs ml-2 text-[#1B95C4] border_btn rounded bg_new font-bold"
                            :disabled="codeState">{{ codeText }}</button>
                        <span v-if="errorStatus.messageCode" class=" text-[10px] text-red-400 ml-3">短信验证码不合法！</span>
                    </div>
                </div>
                <div class=" flex flex-row justify-start items-center mt-3">
                    <div class="w-[120px] text-right p-0 text-sm">
                        校验码:
                    </div>
                    <div class="ml-2 flex flex-row items-center">
                        <input class=" ml-2 py-1 w-[80px] border_color" v-model="form.code" type="text"
                            :class="errorStatus.code ? 'error' : ''">
                        <div class="flex items-center rounded-sm overflow-hidden ml-2" @click="changeCode()">
                            <SecurityCode :identifyCode="identifyCode" />
                        </div>
                        <span v-if="errorStatus.code" class=" text-[10px] text-red-400 ml-3">请输入正确的校验码！</span>
                    </div>
                </div>
                <div class=" flex flex-row items-center justify-start text-xs ml-[128px] mt-5"
                    style="line-height: 20px;">
                    <input type="checkbox" :class="checkStatus.includes('agree') ? '' : 'error'"
                        v-model="checkStatus" name="agree" id="" value="agree">
                    <div class="ml-[-6px]">我已阅读并接受<span class="text-[#4678AD] under">开通服务协议</span></div>
                </div>
                <div class="text-left ml-[128px]">
                    <el-button class="new_btn" type="primary" @click="makeSure">确认付款</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    computed,
    onMounted,
    ref,
    onUnmounted,
    defineEmits
} from "vue";
import { getCodeNo, pay } from '@/api/host'
import { encryptDes } from '@/utils/common'
import { useStore } from "vuex";


const store = useStore()
const emits = defineEmits(['getPayStatus'])
const value = ref('身份证')
const identifyCode = ref('')
const codeText = ref('免费获取')
const codeState = ref(false)
const timer = ref(null)
const cardState = computed(() => store.getters.cardState)
const options = ref([
    {
        label: '身份证',
        value: '0'
    }
])
const form = ref({
    name: null,
    phone: null,
    idCard: null,
    messageCode: null,
    code: null,
})
const checkStatus = ref([])
const errorStatus = ref({
    name: false,
    phone: false,
    idCard: false,
    messageCode: false,
    code: false,
})
const card = computed(() => {
    const num = sessionStorage.getItem('card').toString()
    const str1 = num.slice(0, 4)
    const str2 = num.slice(num.length - 4, num.length)
    const str = str1 + ' **** ' + '**** ' + '**** ' + str2
    return str
})


const getCode = async () => {
    const state = await vaildParms('code')
    if (state) {
        let obj = {}
        if (cardState.value) {
            obj = {
                cardNo: sessionStorage.getItem('card').toString(),
                cardHolder: '',
                phoneNo: form.value.phone,
                identityNo: '',
                merchantOrderNo: store.getters.orderInfo.num
            }
        } else {
            obj = {
                cardNo: sessionStorage.getItem('card').toString(),
                cardHolder: form.value.name,
                phoneNo: form.value.phone,
                identityNo: form.value.idCard,
                merchantOrderNo: store.getters.orderInfo.num
            }
        }
        getCodeNo(obj).then(res => {
            codeState.value = true
            if (res.code !== -1) {
                codeText.value = '60s'
                let num = 60
                timer.value = setInterval(() => {
                    num--
                    codeText.value = num + 's'
                    if (num === 0) {
                        codeState.value = false
                        codeText.value = '免费获取'
                        clearInterval(timer.value)
                    }
                }, 1000)
            } else {
                codeState.value = false
            }
        })

    }

}

const changeCode = () => {
    // var arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    // const num1 = Math.floor(Math.random() * 26);
    // const num2 = Math.floor(Math.random() * 26);
    const str1 = Math.floor(Math.random() * 10).toString();
    const str2 = Math.floor(Math.random() * 10).toString();
    const str3 = Math.floor(Math.random() * 10).toString();
    const str4 = Math.floor(Math.random() * 10).toString();
    const code = str1 + str2 + str3 + str4
    identifyCode.value = code
}

const vaildParms = (type) => {
    let num = 0
    let count = 0
    return new Promise((resolve, reject) => {
        const obj = JSON.parse(JSON.stringify(errorStatus.value))
        const reg1 = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        const phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
        if (form.value.name && form.value.name.length > 1) {
            obj.name = false
            num++
            count++
        } else {
            obj.name = true
        }
        if (reg1.test(form.value.idCard)) {
            obj.idCard = false
            num++
            count++
        } else {
            obj.idCard = true
        }
        if (phoneReg.test(form.value.phone)) {
            obj.phone = false
            num++
            count++
        } else {
            obj.phone = true
        }
        if (type === 'code') {
            errorStatus.value = JSON.parse(JSON.stringify(obj))
            if (count === 3 && !cardState.value) {
                resolve(true)
            } else if(count === 1 && cardState.value) {
                resolve(true)
            } else {
                resolve(false)
            }
            return
        }
        if (form.value.messageCode && (form.value.messageCode.toString().length === 6 || form.value.messageCode.toString().length === 4)) {
            obj.messageCode = false
            num++
        } else {
            obj.messageCode = true
        }
        if (form.value.code && form.value.code.toString().length === 4 && form.value.code.toString() === identifyCode.value) {
            obj.code = false
            num++
        } else {
            obj.code = true
        }
        errorStatus.value = JSON.parse(JSON.stringify(obj))
        if (num == 5 && !cardState.value) {
            resolve(true)
        } else if (num == 3 && cardState.value) {
            resolve(true)
        } else {
            resolve(false)
        }
    })
}

const makeSure = async () => {
    const vaild = await vaildParms('')
    if (vaild) {
        if (checkStatus.value.includes('agree')) {
            pay('1',{smsCode:form.value.messageCode,merchantOrderNo:store.getters.orderInfo.num}).then(res=>{
            if (res.code === 0 && res.url) {
                emits('getPayStatus', { state: true, url:  res.url })
            } else {
                emits('getPayStatus', { state: false, url: '' })
            }
            })
        } else {
            alert('请勾选服务协议！')
        }
    }
}

onMounted(() => {
    changeCode()
})

onUnmounted(() => {
    clearInterval(timer.value)
})

</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.new_bg {
  background: linear-gradient(white, #C4D5E7);
}

.shadow_new {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 1px solid #396C9C;
  border-left: 1px solid #396C9C;
  border-right: 1px solid #396C9C;
  z-index: 1;
}

.error {
  border: 1px solid red !important;
  box-shadow: none !important;
}

.border_color {
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  box-shadow: -1px -1px 1px #c6c6c6;
}

.border_btn {
  border: 1px solid #c6c6c6;
}

.new_btn {
  width: 100px;
  margin-top: 40px;
  background: linear-gradient(#79ACE5, #406695);
}

.under {
  text-decoration: underline #4678AD;
}

.bg_new {
  background: linear-gradient(to right, #BADFEB, white, #BADFEB);
}

:deep(.el-input__wrapper) {
  border-radius: 2px !important;
}

:deep(.select-trigger) {
  box-shadow: -1px -1px 1px #c6c6c6 !important;
}
</style>