<template>
  <div>
    <div class="bg-white flex flex-row justify-center box-border py-3 items-center mt-5">
      <div class="w-[100px] font-bold">银联卡号</div>
      <input
        class="border-none flex flex-1 px-2 py-3 outline-none new_input text-[16px] font-bold"
        @focus="recoverStr"
        @paste="pasteCard"
        @input="changeCard"
        @keyup="carnum = carnum.replace(/[^0-9\s]/g, '')"
        v-model="carnum"
        placeholder="储蓄卡"
      />
    </div>
    <el-button
      class="w-[92%] mt-7"
      color="#ED171F"
      size="large"
      @click="checkCard"
      :disabled="!checkStatus"
      >下一步</el-button
    >

    <Footer />

    <message-boxs :show="toast.showToast" :type="`${checkStatus}`" :text="toast.text" />

  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted, computed, reactive } from "vue";
import { useStore } from "vuex";
import { checkCardNum } from "@/api/host";

const store = useStore();
const carnum = ref(null);
const checkStatus = ref(false);
const toast = ref({
  showToast: false,
  text: "提示",
});
const emits = defineEmits(["getCard"]);
const orderNo = computed(() => store.getters.orderInfo);
const Dom = ref(null);

const checkCard = () => {
  const card = carnum.value.split(/[\t\r\f\n\s]*/g).join("");
  if (card.length > 19 || card.length < 16) {
    Error.value = "error";
    alert("银行卡号正确位数为16位到19位！");
    return false;
  }
  checkCardNum({
    cardNo: card,
    merchantOrderNo: orderNo.value.num,
    deviceType: 2,
    deviceInfo: navigator.userAgent,
  }).then((res) => {
    checkStatus.value = true;
    if (res.code === 0) {
      if (res.url) {
        sessionStorage.clear();
        window.location.replace(res.url);
      } else {
        if (res.result == true || res.result == "true") {
          store.dispatch("user/setCardState", true);
          sessionStorage.setItem("cardStatus", true);
        } else {
          store.dispatch("user/setCardState", false);
          sessionStorage.setItem("cardStatus", false);
        }
        sessionStorage.setItem("card", card);
        emits("getCard", true);
      }
    } else {
      checkStatus.value = false;
      toast.value = JSON.parse(
        JSON.stringify({
          showToast: true,
          text: res.msg,
        })
      );
    }
  });
};

const pasteCard = (e) => {
  const str = e.clipboardData.items[0];
  const block = " ";
  str.getAsString(function (str) {
    const length = str.length;
    if (length > 15 && length < 20) {
      carnum.value =
        str.substring(0, 4) +
        block +
        str.substring(4, 8) +
        block +
        str.substring(8, 12) +
        block +
        str.substring(12, str.length);
      checkStatus.value = true;
    }
  });
};

const recoverStr = (e) => {
  Dom.value = e.target;
};

const changeCard = (e) => {
  const newVal = carnum.value;
  if (e.inputType !== "deleteContentBackward") {
    if (newVal.length > 2) {
      const block = " ";
      if (newVal.length > 22) {
        checkStatus.value = false;
        toast.value = JSON.parse(
          JSON.stringify({
            showToast: true,
            text: "请输入正确的卡号！",
          })
        );
        return false;
      }
      if (
        newVal.length % 5 === 0 &&
        newVal.length !== 16 &&
        newVal.length > 4 &&
        newVal.length < 20
      ) {
        carnum.value =
          newVal.substring(0, newVal.length - 1) +
          block +
          newVal.substring(newVal.length - 1);
        setTimeout(() => {
          Dom.value.focus();
          Dom.value.setSelectionRange(carnum.value.length + 1, carnum.value.length + 1);
        }, 10);
      }
      if (newVal.length < 22 && newVal.length > 18) {
        checkStatus.value = true;
      }
    }
  } else {
    if (newVal.length < 22 && newVal.length > 18) {
      checkStatus.value = true;
    }
  }
};
</script>
