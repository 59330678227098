<template>
    <div class=" absolute bottom-3 w-[100vw]  flex items-center justify-center  " >
        <img class=" h-[30px] w-[80px] mr-1" src="../../assets/logo.png" alt="">
        <span class="w-[1px] h-[20px] ml-3 mr-3 bg-[#ccc]"></span>
        <span class=" text-[#ED171F] font-bold ">支付就用云闪付</span>
    </div>
</template>

<script setup>

</script>


<style lang="scss" scoped>


</style>