import { createStore } from 'vuex';
import user from './modules/user.js';
import getters from './getter.js';

// eslint-disable-next-line new-cap
const store = new createStore({
  modules: {
    // 数据模块化
    user,
  },
  getters,

});
export default store;
