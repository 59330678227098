import { isTemplate } from 'element-plus/es/utils';

<template>
  <div class=" h-full bg-[#F5F5F5]">
    <div class=" relative h-[70px] text-[17px] font-bold text-center" style="line-height: 70px;">
      支付成功
      <!-- <div class=" absolute right-[5%] top-6 text-sm text-[#5A98D2]">
                完成
            </div> -->
    </div>
    <div class="w-[90%] pt-10 px-3 box-border bg-white m-auto rounded-lg new_shadow">
      <div class="mb-5">
        <svg-icon :name="'icon-success-phone'" :size="42" />
      </div>
      <div class="text-2xl font-bold pb-10">
        ￥{{ orderInfo.price }}
      </div>
      <div class="line">
        <span>订单信息</span>
        <span>{{ orderInfo.num }}</span>
      </div>
      <div class="line">
        <span>付款方式</span>
        <span>银联云闪付({{ card }})</span>
      </div>
    </div>
    <div class="mt-5" v-if="backState">
      <el-button type="primary" class="w-[92%] n_btn" size="large" @click="goBack">完成支付({{ time }}s后跳转)</el-button>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, defineProps, watch } from 'vue'
import { useStore } from 'vuex';

const store = useStore()
const prpos = defineProps({
  successState: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ''
  }
})
const backState = ref(false)
const orderInfo = computed(() => store.getters.orderInfo)
const card = computed(() => {
  const str = sessionStorage.getItem('card')
  return str.slice(str.length - 4, str.length)
})
const timer = ref(null)
const time = ref(12)
const _url = ref('')

const goBack = () => {
  sessionStorage.clear()
  if(timer.value !== null){
    clearInterval(timer.value)
    timer.value = null
  }
  window.location.replace(_url.value) 
}

const getTimer = () => {
  let num = 12
  timer.value = setInterval(() => {
    num--
    time.value = num
    if (num === 0) {
      clearInterval(timer.value)
      timer.value = null
      goBack()

    }
  }, 1000)
}

watch(() => prpos.successState, (newval) => {
  if (newval === true) {
    backState.value = true
    getTimer()
  } else {
    backState.value = false
  }
},
  {
    deep: true,
    immediate: true
  }
)

watch(() => prpos.url, (newval) => {
  if (newval) {
    _url.value = newval
  }
},
  {
    deep: true,
    immediate: true
  }
)

onMounted(() => {

})

onUnmounted(() => {
  if (timer.value != null) {
    clearInterval(timer.value)
    timer.value = null
  }

})

</script>

<style lang="scss" scoped>
.new_shadow {
  box-shadow: 0 0 3px #e6e6e6;
}

.n_btn {
  padding: 5px 0;
  font-weight: 600;
  color: #ED171F;
  background-color: white;
  border: 1px solid #ED171F;
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 25px;
  font-weight: 600;
  font-size: 14px;

  span {
    &:nth-child(2) {
      color: #666;
    }
  }
}
</style>
