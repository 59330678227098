import http from '@/utils/http/index';

export const getUrl = () => http.get('/V2.0/openapi/payment/cashier1', {
  params: {},
});

export const checkCardNum = (data) => http.post('/payurl/quickPay/checkCardNo', {
  params: JSON.stringify(data),
});

export const getCodeNo = (data) => http.post(`/payurl/quickPay/sms`,{
  params:JSON.stringify(data),
});

export const pay = (param,data) => http.post(`/payurl/quickPay/confirm`,{
  params:JSON.stringify(data),
});


export default { getUrl, checkCardNum, getCodeNo, pay };
