
const state = {
  orderInfo: sessionStorage.getItem('order') ? JSON.parse(sessionStorage.getItem('order')) : {},
  cardState:sessionStorage.getItem('cardState') ? JSON.parse(sessionStorage.getItem('cardState')) : null,
};

const mutations = {
  SET_ORDER_INFO(state, data) {
    // eslint-disable-next-line no-param-reassign
    sessionStorage.setItem('order',JSON.stringify(data))
    state.orderInfo = data;
  },
  SET_CARD_STATE(state, status){
    sessionStorage.setItem('cardState',status)
    state.cardState = status;
  }

};

const actions = {
  setInfo({ state, commit }, data) {
    commit('SET_ORDER_INFO', data)
  },
  setCardState({ state, commit }, status) {
    commit('SET_CARD_STATE', status)
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
