<template>
  <div class="w-full h-[259px] indent-2 bg-[#C4D5E7] box-border p-1">
    <div class="w-full h-[250px] bg-white flex flex-col justify-center items-center">
      <div class="flex flex-col justify-start">
        <span class=" text-left p-0 text-sm">
          请输入您要付款的银联卡号:
        </span>
        <div>
          <input class=" w-[320px] py-3 border_color mt-5 new_input font-bold new_input" :class="Error"
            @focus="recoverStr" v-model="carnum" @input="changeCards" @paste="pasteCard" type="text"
            @keyup="carnum = carnum.replace(/[^0-9\s]/g, '')" placeholder="储值卡">
        </div>
        <div class=" text-left">
          <el-button class="new_btn" @click="checkCard" type="primary">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, watch, onMounted } from 'vue';
import { checkCardNum } from '@/api/host'
import { useStore } from 'vuex';
import Fingerprint2 from 'fingerprintjs2'

const emits = defineEmits('getCard')
const store = useStore()
const carnum = ref('')
const Error = ref()
const browser = ref()
const orderNo = computed(() => store.getters.orderInfo)
const Dom = ref(null)


const checkCard = () => {
  const card = carnum.value.split(/[\t\r\f\n\s]*/g).join('')
  if (card.length > 19 || card.length < 16) {
    Error.value = 'error'
    alert('银行卡号正确位数为16位到19位！')
    return false
  }
  checkCardNum({ cardNo: card, merchantOrderNo: orderNo.value.num,deviceType:1,deviceInfo:navigator.userAgent }).then(res => {
    Error.value = ''
    if (res.code === 0) {
      if (res.url) {
        window.location.replace(res.url)
      } else {
        if (res.result) {
          store.dispatch('user/setCardState', true)
        } else {
          store.dispatch('user/setCardState', false)
        }
        sessionStorage.setItem('card', card)
        emits('getCard', true)
      }
    } else {
      Error.value = 'error'
      alert(res.msg)
    }

  })
}

const pasteCard = (e) => {
  const str = e.clipboardData.items[0]
  const block = ' '
  str.getAsString(function (str) {
    const length = str.length
    if (length > 15 && length < 20) {
      carnum.value = str.substring(0, 4) + block + str.substring(4, 8) + block + str.substring(8, 12) + block + str.substring(12, str.length)
    }
  })
}

const recoverStr = (e) => {
  Dom.value = e.target
}

const changeCards = (e) => {
  const newVal = carnum.value.toString()
  if (e.inputType !== 'deleteContentBackward') {
    if (newVal.length > 2) {
      const block = ' '
      if (newVal.length > 22) {
        Error.value = 'error'
        alert('银行卡号正确位数为16位到19位！')
        Dom.value.blur()
        return
      }
      if (newVal.length < 22 && newVal.length > 18) {
        Error.value = ''
      }
      if (newVal.length % 5 === 0 && newVal.length !== 16 && newVal.length > 4 && newVal.length < 20) {
        carnum.value = newVal.substring(0, newVal.length - 1) + block + newVal.substring(newVal.length - 1)
        setTimeout(() => {
          Dom.value.focus()
          Dom.value.setSelectionRange(carnum.value.length + 1, carnum.value.length + 1)
        }, 10)
      }

    }
  } else {
    if (newVal.length < 22 && newVal.length > 18) {
      Error.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.new_bg {
  background: linear-gradient(white, #C4D5E7);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}

.shadow_new {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 1px solid #396C9C;
  border-left: 1px solid #396C9C;
  border-right: 1px solid #396C9C;
  z-index: 1;
}

.border_color {
  border: 1px solid #c6c6c6;
}

.error {
  border: 1px solid red;

  &:focus {
    outline: 1px solid red;
  }
}

.new_input {
  font-size: 15px;
  letter-spacing: 1px;
}

.new_btn {
  width: 100px;
  margin-top: 40px;
  background: linear-gradient(#79ACE5, #406695);
}
</style>