import cryptoJs from 'crypto-js'


export const mobile = () => navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);


export const encryptDes = (message)=>{
    /* 这里的模式参数需要和后端匹配 mode.ECB，mode.CBC*/
    var keyHex = cryptoJs.enc.Utf8.parse('skk33g1235')
    var encrypted = cryptoJs.DES.encrypt(message, keyHex, { 
    	mode: cryptoJs.mode.ECB, 
    	padding: cryptoJs.pad.Pkcs7 
    });
    return encrypted.ciphertext.toString();
}

export const decryptDes = (message)=>{
    var keyHex = cryptoJs.enc.Utf8.parse('skk33g1235')
    var decrypted = cryptoJs.DES.decrypt(
        {
            ciphertext: cryptoJs.enc.Hex.parse(message)
        },
        keyHex,
        {
            mode: cryptoJs.mode.ECB,
            padding: cryptoJs.pad.Pkcs7
        }
    )
    return decrypted.toString(cryptoJs.enc.Utf8)
}