/* eslint-disable no-plusplus */
import login from '@/api/login';
import axios from 'axios';
import { ElMessageBox } from 'element-plus'
import store from '@/store';
// import 'element-plus/es/components/notification/style/css';
// import 'element-plus/es/components/loading/style/css';
// 基础地址
// const baseURL = window.rootData.ROOT_SERVER;
const baseURL = '/api';

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.timeout = 30000;
// 控制loading 变量
let reqNum = 0;
let loading = null;
let platform = '1';
// eslint-disable-next-line max-len
const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
if (flag) {
  platform = '1';
} else {
  platform = '2';
}

const request = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API  : '',
});

const startLoading = () => {
  if (reqNum === 0) {
    // loading 开始
    loading = ElLoading.service({
      lock: true,
      text: '请求中...',
      background: 'rgba(0, 0, 0, 0.2)',
    });
  }
  reqNum++;
};

const endLoading = () => {
  if (reqNum <= 0) return;
  reqNum--;
  if (reqNum === 0) {
    // loading 结束
    loading.close();
  }
};
// export const serviceJson = axios.create({ adapter });

// eslint-disable-next-line no-use-before-define
// eslint-disable-next-line no-use-before-define
// interceptors(serviceJson);

// 创建一个错误
function errorCreat(msg, response) {
  const err = new Error(msg);
  // eslint-disable-next-line no-use-before-define
  errorLog(err, response);
  throw err;
}
// 创建一个错误,且返回结果
function errorCreat2(msg, response) {
  const err = new Error(msg);
  // eslint-disable-next-line no-use-before-define
  errorLog(err, response);
}
// 记录和显示错误
function errorLog(err, response) {
  ElMessageBox.alert(`${err.message || err.msg || '出错了，请联系管理员'}`, '提示', {
    // if you want to disable its autofocus
    // autofocus: false,
    type: 'error',
    showClose: false,
    closeOnClickModal: true,
    showConfirmButton: false,
    callback: () => {

    }
  })
  // 显示提示
  // ElNotification({
  //   title: 'Error',
  //   message: `${err.message}`,
  //   type: 'error',
  // });
  // ElMessage({
  //   message: `${err.message}`,
  //   type: 'error',
  // })
}

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-unused-expressions
    config.isLoading && startLoading();
    if (window.localStorage.getItem('token')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.token = window.localStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // if(window.sessionStorage.getItem('roomId')){
    //   config.headers['roomId'] = window.sessionStorage.getItem('roomId'); 
    // }
    // eslint-disable-next-line no-param-reassign
    config.headers['Content-Type'] = 'application/json';
    // eslint-disable-next-line no-param-reassign
    config.headers.platform = platform;
    return config;
  },
  (error) => {
    // 发送失败
    Promise.reject(error);
  },
);

// 响应拦截器
request.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response;
    endLoading();
    // 这个状态码是和后端约定的
    let code = '';
    if (dataAxios.data.code !== undefined) {
      code = dataAxios.data.code;
    } else {
      return dataAxios;
    }
    // 根据 code 进行判断
    // if (code === undefined) {
    //   // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
    //   return dataAxios;
    // }
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    // eslint-disable-next-line no-unused-expressions
    switch (code) {
      case 0:
        return dataAxios.data;
      case -1:
        errorLog(response.data, response);
        return Promise.reject(response.data);
      case 'success':
        return dataAxios.data;
      case 'warnning':
        return dataAxios.data;
      case 'fail':
        // [ 示例 ] 其它和后台约定的 code
        errorCreat(dataAxios.data.msg, dataAxios);
        break;
      case '1000':
        // [ 示例 ] 其它和后台约定的 code
        // router.replace({
        //   path: "/login"
        // });
        // sessionStorage.clear();
        errorCreat2('登录状态失效，请重新登录', dataAxios);
        // break;
        return dataAxios;
      default:
        // 不是正确的 code
        errorCreat('服务器内部错误,请联系管理员', dataAxios);
        break;
    }
  },
  (error) => {
    // eslint-disable-next-line no-unused-expressions
    endLoading();
    if (error && error.response.status) {
      console.log(error.response.status);
      switch (error.response.data.status || error.response.status) {
        case -1:
          // eslint-disable-next-line no-param-reassign
          error.message = '请求错误';
          break;
        case 400:
          // eslint-disable-next-line no-param-reassign
          // console.log( error.response.data.message);
          error.message = error.response.data.message;
          break;
        case 401:
          // eslint-disable-next-line no-param-reassign
          error.message = '未授权，请登录';
          break;
        case 403:
          // eslint-disable-next-line no-param-reassign
          error.message = '拒绝访问';
          break;
        case 404:
          // eslint-disable-next-line no-param-reassign
          error.message = '请求地址出错，请联系管理员';
          break;
        case 408:
          // eslint-disable-next-line no-param-reassign
          console.log(500);
          error.message = '请求超时';
          break;
        case 500:
          // eslint-disable-next-line no-param-reassign
          error.message = '未知错误，请联系管理员';
          break;
        case 501:
          // eslint-disable-next-line no-param-reassign
          error.message = '服务未实现';
          break;
        case 502:
          // eslint-disable-next-line no-param-reassign
          error.message = '网关错误';
          break;
        case 503:
          // eslint-disable-next-line no-param-reassign
          error.message = '服务不可用';
          break;
        case 504:
          // eslint-disable-next-line no-param-reassign
          error.message = '网关超时';
          break;
        case 505:
          // eslint-disable-next-line no-param-reassign
          error.message = 'HTTP版本不受支持';
          break;
        default:
          // eslint-disable-next-line no-param-reassign
          error.message = '未知错误，请联系管理员';
          break;
      }
    }
    errorLog(error, error.response);
    // dataAxios.config.isLoading && endLoading();
    return Promise.reject(error);
  },
);
export default request;
