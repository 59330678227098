<template>
  <div class=" relative flex flex-col justify-center w-[945px] h-[200px] bg-[#E9F4F6] m-auto mt-3 border_new">
    <div class=" flex flex-row ml-[100px]">
      <svg-icon :name="'icon-success'" />
      <div class="flex flex-col text-left ml-5">
        <div class="text-left font-bold">您已成功支付<span class="text-[20px] text-[#FE5C05]">{{ price }}</span>元</div>
        <div class="flex flex-row items-center mt-5" v-if="backState">
          <img class="loading_img" src="../../assets/loading.png" alt="">
          <div class="text-sm font-bold ml-2"><span class="text-[#3B6C9F]">{{ time }}</span>秒之后自动跳转商户页</div>
          <el-button class="new_btn ml-3" type="primary"  @click="goBack">点击立即跳转</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const prpos = defineProps({
  successState: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ''
  }
})
const time = ref('12')
const timer = ref(null)
const store = useStore()
const price = ref(store.getters.orderInfo.price)
const _url = ref('')
const backState = ref(false)
const goBack = () =>{
  sessionStorage.clear()
  if(timer.value !== null){
    clearInterval(timer.value)
    timer.value = null
  }
  window.location.replace(_url.value) 
 
}

const getTimer = ()=>{
  let num = 12
   timer.value = setInterval(()=>{
    num--
    time.value = num
    if(num === 0){
      clearInterval(timer.value)
      timer.value = null
      goBack()
      
    }
  },1000)
}

watch(()=>prpos.successState,(newval)=>{
  if(newval === true){
    backState.value = true
    getTimer()
  } else {
    backState.value = false
  }
},
{
  deep:true,
  immediate:true
}
)

watch(()=>prpos.url,(newval)=>{
  if(newval){
    _url.value = newval
  }
},
{
  deep:true,
  immediate:true
})


onUnmounted(()=>{
  if(timer.value !== null){
    clearInterval(timer.value)
    timer.value = null
  }
  
})
</script>

<style lang="scss" scoped>
.border_color {
  border: 1px solid #c6c6c6;
}

.border_new {
  border: 2px solid #ADC7E0;
}

@keyframes trun {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(4320deg);
  }
}

.loading_img {
  width: 14px;
  height: 14px;
  animation: trun 12s linear infinite;
  ;
}




.new_btn {
  width: 120px;
  background: linear-gradient(#79ACE5, #406695);
}
</style>